@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';

.container {
  padding-bottom: 48px;

  @include laptop-bp {
    padding-bottom: 88px;
  }

  .navigation {
    width: fit-content;
    margin: 80px auto 0;
    text-align: center;

    * {
      color: $black;
      @include subHeading5($font-manrope-normal);
      font-size: 11px;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.2px;
      text-transform: uppercase;
    }

    button {
      margin-top: 12px;
      text-decoration-line: underline;
    }
  }
}

.productGridContainer {
  max-width: $content-max-width;
  margin-inline: auto;
  position: relative;

  .productGrid {
    display: grid;
    column-gap: 8px;
    row-gap: 40px;
    grid-template-columns: 1fr;
    padding: 16px;

    @include laptop-bp {
      row-gap: 48px;
      padding: 0 32px;
    }

    &__oneCol {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    &__twoCol {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    &__threeCol {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    &__fourCol {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.backToTopIcon {
  bottom: 100px;
  position: sticky;
  cursor: pointer;
  margin-right: 0;
  display: flex;
  width: 100%;
  justify-content: flex-end;

  @include laptop-bp {
    margin-left: -8px;
  }
}

.listWrapper {
  position: relative;
}
