@import '../../../../styles/config/variables';

.backdrop {
  background-color: $tp-house-pink-2;
  z-index: 100;

  transition: all 0.2s ease-in 0.2s;
  visibility: hidden;
  opacity: 0;

  &__open {
    visibility: visible;
    opacity: 0.8;
  }
}

.container {
  width: 100%;
  height: calc(100% - 48px);
  position: fixed;
  top: 48px;
  z-index: 100;
  background-color: $white;

  transition: transform 0.3s ease;
  transform: translateX(-100%);

  &__open {
    transform: translateX(0);
  }
}
