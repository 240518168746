@import '../../../styles/mixins/layout-mixins';
@import '../../../styles/mixins/font-mixins';
@import '../../../styles/mixins/element-mixins';
@import '../../../styles/config/variables';

$header-collapsed-height: 70px;

.container {
  margin: 0 auto;
  position: sticky;
  top: $header-collapsed-height;
  z-index: 2;

  &__alertOffset {
    top: calc($header-collapsed-height + $alert-banner-height-mobile);

    @include laptop-bp() {
      top: calc($header-collapsed-height + $alert-banner-height);
    }
  }

  .menuWrapper {
    position: relative;
    background-color: $white;

    border-top: 1px solid $tp-border-grey;
    border-bottom: 1px solid $tp-border-grey;

    .menu {
      max-width: $content-max-width;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      padding: 16px;

      & > * {
        flex-shrink: 0;
      }

      &__desk {
        justify-content: flex-start;
        padding: 16px 32px;
        gap: 32px;
      }

      .gridLayoutBtn {
        display: flex;
        flex: 1;
        justify-content: center;
        gap: 4px;
        align-items: center;
        cursor: pointer;

        &__desk {
          justify-content: flex-end;
        }

        &__text {
          text-transform: uppercase;
          @include subHeading5;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 2.2px;
          text-transform: uppercase;
        }

        &__icon {
          display: flex;
          gap: 2px;
          flex-direction: row;
          &__inverted {
            flex-direction: column;
          }
        }

        &__line {
          width: 2px;
          height: 12px;
          background-color: $tp-house-pink;

          &__mobile {
            width: 5px;
            height: 12px;
          }
          &__mobileInverted {
            width: 12px;
            height: 5px;
          }
        }
      }

      .filterBtn,
      .sortBtn {
        display: flex;
        align-items: center;
        @include subHeading5;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 2.2px;
        text-transform: uppercase;
        gap: 8px;

        .chevron {
          height: 12px;
          width: 12px;
          rotate: -180deg;
          transition: rotate 0.3s ease;
        }

        &:hover,
        &__open {
          text-shadow: 0px 0px 1px $tp-black;

          .chevron path {
            stroke-width: 2;
          }
        }

        &__open {
          .chevron {
            rotate: 0deg;
          }
        }
      }
    }

    .dropdown {
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      width: 100%;
      background-color: $tp-white;
      border-bottom: 1px solid $tp-border-grey;
      z-index: 2;

      ul {
        max-height: 180px;
        max-width: $content-max-width;
        display: flex;
        flex-flow: column wrap;
        margin: 0 auto;
        padding: 24px 32px;
        gap: 20px 32px;

        .filterOption,
        .sortOption {
          width: 174px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }

        .filterOption {
          // TODO: REFACTOR, tailwind is overriding our styling, once we remove it we should remove !important
          input {
            height: 16px !important;
            width: 16px !important;
            border: none !important;
            border: 1px solid $tp-rebuild-grey-06 !important;
            box-shadow: none !important;
            background-color: $tp-white !important;
            border-radius: 0;
            cursor: pointer;

            &:focus,
            &:focus-visible {
              border: none;
              box-shadow: none;
            }

            &:checked {
              border: none;
              background-color: $tp-rebuild-grey !important;
              border-color: $tp-rebuild-grey !important;
            }
          }

          label {
            margin-left: 8px;
            @include paragraph-S;
            color: $tp-black;
            text-transform: capitalize;
            cursor: pointer;
          }
        }

        .sortOption {
          @include paragraph-S;
          color: $tp-black;
          text-transform: capitalize;

          &:hover,
          &__selected {
            text-shadow: 0px 0px 1px $tp-black;

            .chevron path {
              stroke-width: 2;
            }
          }
        }
      }
    }
  }

  .results {
    display: flex;
    max-width: $content-max-width;
    margin: 0 auto;
    padding: 16px 32px;
    background-color: $white;

    .productNumber {
      margin-right: 24px;
      @include paragraph-S;
      font-weight: 500;
      color: $black;

      span {
        font-weight: 700;
      }
    }

    .selectedFilters {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      &__collapsed {
        max-height: 90px;
        overflow: hidden;
      }

      .selectedFilter {
        width: fit-content;
        display: flex;
        align-items: center;
        padding: 8px 12px;
        border-radius: 100px;
        background-color: $tp-rebuild-grey-005;
        @include paragraph-S;
        cursor: pointer;

        .removeFilter {
          width: 16px;
          height: 16px;
          margin-left: 12px;
        }
      }

      .btnContainer {
        display: flex;
        gap: 16px;
        background: white;

        &__collapsed {
          position: absolute;
          bottom: 0;
          right: 0;
        }

        button {
          padding: 8px 12px;
          @include paragraph-S;
          text-transform: uppercase;
          font-weight: 500;
          text-decoration: underline;
          color: $tp-black;
        }
      }
    }
  }
}

$drawer-btns-height: 70px;
.drawer {
  position: relative;
  height: 100%;
  display: block;
  overflow-y: auto;
  overflow-x: hidden;

  .heading {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    background-color: $tp-rebuild-grey-005;
    padding: 24px 16px;
    cursor: pointer;
    z-index: 1;

    .chevron {
      height: 12px;
      width: 12px;
      rotate: -90deg;
      margin-right: 8px;
    }

    h1 {
      @include heading1($font-manrope-normal);
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
      color: #000;
      letter-spacing: 2.4px;
    }
  }

  .content {
    height: calc(100% - $drawer-btns-height);
    overflow: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;
      border-bottom: 1px solid $tp-rebuild-grey-005;
      cursor: pointer;

      span {
        @include paragraph-S;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2.4px;
        text-transform: uppercase;
      }

      .chevron {
        height: 12px;
        width: 12px;
        rotate: 90deg;
      }
    }
  }

  .drawerFilterOptions {
    position: absolute;
    top: 0;
    right: -100%;
    width: 100%;
    z-index: 10;
    height: calc(100% - $drawer-btns-height);
    transition: all 0.3s ease;
    background-color: $tp-white;
    overflow: auto;

    &__open {
      right: 0;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px 16px;
      border-bottom: 1px solid $tp-rebuild-grey-005;
      text-transform: capitalize;
      cursor: pointer;

      label {
        @include paragraph-S;
        font-size: 14px;
        font-weight: 500;
        line-height: 146%;
        letter-spacing: 0.24px;
      }

      input {
        border: none !important;
        border: 1px solid $tp-rebuild-grey-06 !important;
        box-shadow: none !important;
        background-color: $tp-white !important;
        cursor: pointer;

        &:focus,
        &:focus-visible {
          border: none;
          box-shadow: none;
        }

        &:checked {
          border: none;
          background-color: $tp-rebuild-grey !important;
          border-color: $tp-rebuild-grey !important;
        }
      }
    }
  }

  .drawerBtns {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: $drawer-btns-height;
    display: flex;

    button {
      padding: 24px 0px;

      @include paragraph-S;
      font-size: 11px;
      font-weight: 500;
      letter-spacing: 2.2px;
      text-transform: uppercase;
    }

    .btnClear {
      flex: 1;
      border-top: 1px solid $tp-rebuild-grey-005;
    }

    .btnApply {
      flex: 2;
      background-color: $tp-black;
      color: $tp-white;
    }
  }
}
